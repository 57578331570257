import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { isNullishCoalesce } from 'typescript';
import axios from 'axios';

const RandomImage: React.FC = ({}) => {

    const [imageUrl, setImageUrl] = useState<String>();

    useEffect(() => {        
        //axios.get("http://localhost:3008/images/random")
        axios.get("https://api.maniar.pl/images/random")
            .then((response) => {
                setImageUrl(response.data);   
            console.log(response.data);          
        }, (error) => {});
    }, []);

    return (
        <div>
            <img src={`${imageUrl}`}></img>
        </div>
    );
};

export default RandomImage;