import React from 'react';
import './App.css';
import RandomImage from './components/RandomImage';
import VisitorsCounter from './components/VisitorsCounter';

function App() {
  return (
    <div className="app">
      <div className="logo">MANIAR.PL</div>
      <div className="name">puszyste bezglutenowe systemy informatyczne</div>
      <RandomImage />
      <VisitorsCounter />
    </div>
  );
}

export default App;
