import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { isNullishCoalesce } from 'typescript';
import axios from 'axios';

const VisitorsCounter: React.FC = ({}) => {

    const colors: string[] = ['#C05095', '#8512A1', '#4D0D80', '#2F0E53', '#B63082'];
    
    const [visitors, setVisitors] = useState<Number>();

    useEffect(() => {        
        //axios.get("http://localhost:3008/visitors")
        axios.get("https://api.maniar.pl/visitors")
            .then((response) => {
                setVisitors(response.data.value);   
            console.log(response.data);          
        }, (error) => {});
    }, []);

    let lastColor = '';

    function getRandomColor() {
        let randomIndex;
        let color;
        
        // Generate new random indices until a different color is picked
        do {
          randomIndex = Math.floor(Math.random() * colors.length);
          color = colors[randomIndex];
        } while (color === lastColor);
        
        lastColor = color;
        return color;
      }

    const zeroPad = (num: Number | undefined, places: number) => String(num).padStart(places, '0')

    const convertUndefinedToZero = (num: Number | undefined) => {
        if (num === undefined) {
            return 0;
        } else {
            return num;
        }
    }

    const convertCountToSpan = (count: String) => {
        return count.split('').map((digit, index) => {
            return <span key={index} style={{color: getRandomColor()}}>{digit}</span>
        })
    }

    return (
        <div className='counter'>
            <div>licznik odwiedzin</div>
            <div>{convertCountToSpan(zeroPad(visitors, 7))}</div>            
        </div>
    );
};

export default VisitorsCounter;